import React from "react"
import { FaChevronCircleRight } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WhatIsElderLawMedicaidPlanning = () => (
  <Layout>
    <SEO title="What is Elder Law &amp; Medicaid Planning?" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">
              What is Elder Law &amp; Medicaid Planning?
            </h1>
            <p>
              As Elder Law attorneys, we specialize in the legal concerns of
              senior citizens and younger individuals who are planning for the
              future. We focus our practice on:
            </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Medicare and Medicaid planning and advocacy;
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                estate planning, including Trusts, Wills, Powers of Attorney,
                and Deeds;
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                Social Security benefits;
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                probate, including decedents’ estates, guardianships, and
                conservatorships.
              </li>
            </ul>
            <p>
              We also review long-term care insurance policies to make sure our
              clients receive what they are paying for.
            </p>
            <p>
              Rysso Law, PLLC is widely known for Medicaid planning to receive
              government benefits to help pay for nursing home costs. Our
              expertise in Medicaid planning is recognized statewide; other
              Elder Law attorneys consult with us to answer questions or improve
              their own practices.
            </p>
            <p>
              We receive many referrals in cases where people received either no
              advice or incorrect advice, and now have a problem with Medicaid
              eligibility or something related to their estate plan. Although we
              cannot work magic, we know the laws and know what needs to be done
              from this point forward. And sometimes we can fix the problem
              completely. We frequently hear “I wish we had known about you” or
              “I wish we had come to you first.” Although it is always better to
              handle matters properly from the start, sometimes that does not
              happen, and we are happy to help “clean up” any problems.
            </p>
            <p>
              The most important thing to remember with Medicaid planning is
              that if you come to us for planning you will not have to
              “spend-down” all of your assets. We can accomplish Medicaid
              planning at any time, even after a loved one is already in a
              nursing home.
            </p>
            <p>
              More than protecting assets, we believe in caring for our client’s
              emotional well-being. Our goal is to give our clients peace of
              mind. Once we are retained, we handle many of the issues that
              arise from entry into a nursing home, including:
            </p>
            <ul className="fa-ul content">
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                working with the billing department at the nursing home;
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                handling all correspondence with the Michigan Department of
                Human Services;
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                making sure all financial transactions are completed; and
              </li>
              <li>
                <span className="fa-li">
                  <FaChevronCircleRight color="#A42E2D" />
                </span>
                answering all of the family’s questions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default WhatIsElderLawMedicaidPlanning
